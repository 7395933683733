export const HEADING_WRAPPER_SELECTOR = ".hero-animation__trigger--heading";
export const HERO_SECTION_SELECTOR = ".hero-animation__trigger--section";
export const TARGET_HEADING_SELECTOR = ".hero-animation__target";
export const CHANGING_TAGLINES_SELECTOR = ".changing-taglines__container";
export const TAGLINE_SELECTOR = ".changing-taglines__tagline";
export const TYPING_LINK_SELECTOR = ".typing__link";
export const TYPING_TEXT_SELECTOR = ".typing__text";
export const TICKER_TAPE_SELECTOR = ".ticker-tape__link";
export const TICKER_TAPE_TEXT_SELECTOR = ".ticker-tape__text";
export const YT_DEFER_SELECTOR = ".ytdefer";
export const CLIENT_FILTER_BUTTON_SELECTOR = ".button--clients";
export const WHO_WE_WORK_WITH_SECTION_SELECTOR = "#who-we-work-with";
export const DROPDOWN_TOGGLE_SELECTOR = ".nav__dropdown-toggle";
export const FILTER_BUTTON_LIST_SELECTOR = ".filter__button-list";
export const FILTER_RESET_BUTTON_SELECTOR = ".filter__all-projects-button";
export const DROPDOWN_LINK_SELECTOR = ".nav__dropdown-link";
export const CARD_LINK_SELECTOR = ".card__link";
export const IMAGE_CONTAINER_SELECTOR = ".card__image-container";
export const CARD_IMAGE_SELECTOR = ".card__image";
export const BETTER_TOGETHER_CONTAINER_SELECTOR = ".better-together__container";
export const BETTER_TOGETHER_LINE_SELECTOR = ".better-together__line";
